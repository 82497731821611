<template>
    <el-popover placement="top" width="300" v-model="visible">
        <div class="captcha">
            <div class="left">
                <el-input v-model="imgCode" placeholder="图片验证码" clearable></el-input>
            </div>

            <div class="right">
                <img v-if="imgSrc" :src="imgSrc" alt="captcha" @click="queryCaptchaImg" />
            </div>
        </div>

        <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="cancelHandler">取消</el-button>
            <el-button type="primary" size="mini" @click="confirmHandler">确定</el-button>
        </div>

        <template slot="reference">
            <slot></slot>
        </template>
    </el-popover>
</template>

<script>
export default {
    name: 'Captcha',
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            visible: false,

            imgSrc: '',

            imgCode: '',
            checkKey: '',
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
        data() {
            return {
                checkKey: this.checkKey,
            }
        }
    },
    watch: {
        imgCode(val) {
            this.model.imgCode = val;
        },
    },
    methods: {
        queryCaptchaImg() {
            this.checkKey = `${new Date().getTime()}`

            this.$get(
                `${this.$store.state.api.baseUrl}/notify/captcha`,
                (data) => {
                    this.imgSrc = data.captcha;
                    this.checkKey = this.model.checkKey = data.key;
                }
            );
        },
        validate() {
            if (!this.imgCode) {
                ShowMsgBox('获取手机签证码前，请先输入图片验证码完成校验', 'error');

                this.queryCaptchaImg();

                return false;
            }

            return true;
        },
        confirmHandler() {
            this.visible = false;

            if (!this.validate()) {
                return;
            }

            this.$emit('click');
        },
        cancelHandler() {
            this.visible = false;

            this.imgCode = '';
        },
    },
    created() {
        this.imgCode = '';
    },
    mounted() {
        this.queryCaptchaImg();
    }
};
</script>

<style scoped lang="scss">
/* Your styles here */
.captcha {
    display: flex;
    align-items: center;

    .left {
        flex: 1;
        margin-right: 10px;
    }

    .right {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
</style>