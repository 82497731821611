<template>
    <div class="jian-tou-auto-login">

    </div>
</template>
<script>

import { mapMutations } from 'vuex';

import LOGIN_SUCCESS from '../mixins/login-success.js';

export default {
    mixins: [LOGIN_SUCCESS],
    components: {
    },
    data() {
        return {

        }
    },
    methods: {
        ...mapMutations({
            userinfoUpdata: 'userinfo/userinfoUpdata', //用户信息
        }),
        autoLoginHandler() {
            const jiantouApplyGuid = this.getQuery('jiantouApplyGuid');

            if (!jiantouApplyGuid) {
                return;
            }

            new Chain().link(next => {
                let sendForm = {
                    jiantouApplyGuid,
                };

                this.$post(`${this.$store.state.api.baseUrl}/client/jiantouLogin`, sendForm, (data) => {
                    this.userinfoUpdata(data);

                    showMsg('登录成功', 'success');

                    next();
                })
            }).link(next => {
                this.loginSuccess();
            }).run();
        },
    },
    created() {
    },
    mounted() {
        this.autoLoginHandler();
    },
}
</script>
<style lang="scss" scoped>
.my-form {
    padding-top: 10px;
}

.el-form .el-form-item:nth-child(-n+2) {
    margin-bottom: 30px;
}

::v-deep .el-form .el-form-item:nth-last-child(1) {
    margin-bottom: 16px !important;
}
</style>
