<template>
    <el-dialog
        title="忘记密码"
        :visible.sync="model"
        width="380px"
        center
        top="15%"
        :close-on-click-modal="false"
    >
        <el-form
            :model="phoneform"
            ref="phoneform"
        >
            <el-form-item
                prop="mobile"
                :rules="newRule('手机号', 'required', 'mobile')"
            >
                <el-input
                    placeholder="请输入手机号"
                    v-model="phoneform.mobile"
                    clearable
                >
                </el-input>
            </el-form-item>
            <el-row :gutter="10">
                <el-col :span="11">
                    <el-form-item
                        prop="captcha"
                        :rules="newRule('验证码', 'required')"
                    >
                        <el-input
                            placeholder="手机验证码"
                            v-model="phoneform.captcha"
                            clearable
                        ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="13">
                    <Captcha ref="captcha" v-model="phoneform" @click="sendcode">
                        <el-button
                            style="width:100%;"
                            :disabled="disabled"
                        >{{text}}</el-button>
                    </Captcha>
                </el-col>
            </el-row>
            <el-form-item
                prop="newpwd"
                :rules="[...newRule('密码', 'required'), {validator:checkPwd, trigger: 'blur'}]"
                style="margin-bottom: 10px;"
            >
                <el-input
                    type="password"
                    placeholder="请输入新密码"
                    v-model="phoneform.newpwd"
                    clearable
                >
                </el-input>
            </el-form-item>

            <div style="margin-bottom:10px;">
                提示：密码为6位以上数字字母组合。
            </div>

            <el-button
                type="success"
                style="width:100%;font-size:18px;"
                @click="findpwd"
            >确定</el-button>
        </el-form>
    </el-dialog>
</template>

<script>
import Captcha from '@components/sys/captcha.vue';

export default {
    components: {
        Captcha
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            disabled: false,
            text: '发送验证码',
            phoneform: {
                newpwd: '',
                mobile: '',
                captcha: '',
            },
        };
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    methods: {
        checkPwd(rule, value, callback) {
            if (!/^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]+$/.test(value)) {
                callback(new Error('密码格式错误！'));
            } else {
                callback();
            }
        },
        sendcode: function () {
            if (!this.phoneform.mobile) {
                showMsg('请先输入手机号！', 'warning');
                return;
            }

            this.$post(
                // /client/sendcaptcha.json 
                `${this.$store.state.api.baseUrl}/notify/sendForgetPasswordCode`,
                {
                    mobile: this.phoneform.mobile,
                    islogin: true,

                    checkKey: this.phoneform.checkKey,
                    imgCode: this.phoneform.imgCode,
                },
                (data) => {
                    ShowMsg('验证码已发送', 'success');
                    this.settime();
                }
            );
        },
        settime: function () {
            this.text = 60;
            this.disabled = true;

            var interval = setInterval(() => {
                this.text--;
                if (this.text == 0) {
                    this.disabled = false;
                    this.text = '发送验证码';
                    clearInterval(interval);
                }
                return this.text;
            }, 1000);
        },
        findpwd: function () {
            this.$refs['phoneform'].validate((valid) => {
                if (valid) {
                    this.$post(
                        `${this.$store.state.api.baseUrl}/userreg/findpwd.json`,
                        {
                            mobile: this.phoneform.mobile,
                            captcha: this.phoneform.captcha,
                            newpwd: this.phoneform.newpwd,
                        },
                        (data, res) => {
                            showMsg(res.msg, 'success');
                            this.model = false;
                        }
                    );
                } else {
                    return false;
                }
            });
        },
    },
};
</script>